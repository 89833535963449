<template>
  <div class="flex justify-between items-center gap-4">
    <PageTitle :title="pageTitle" lass="flex-fill" />
    <div
      class="w-64"
      v-if="organizationOptions.length > 0 && checkPermission('*')"
      id="searchOrganization"
    >
      <BaseSelect
        :label="$t('label_organizations')"
        v-model="searchOrganization"
        name="searchOrganization"
        class="base-select flex items-center gap-4"
        :options="[
          {
            value: '',
            label:  t('label_all'),
          },
          ...organizationOptions,
        ]"
      />
    </div>
  </div>
  <PoolList :pools="filteredPools" v-if="filteredPools.length" />
  <div class="text-center font-bold opacity-60" v-if="!filteredPools.length && !freeVms?.length">
    {{ $t("text_no_pools_available") }}
  </div>
  <div v-if="freeVms?.length" >
    <h2 v-if="showVmTitle" class="text-2xl font-bold mb-4">{{ $t("label_virtual_machines") }}</h2>
    <VirtualMachine  :data="freeVms" :pool-id="'1beafd3138e0c8c0'" :withTitle="false"/>
  </div>
</template>

<script setup>
import VirtualMachine from "@/components/pool/VirtualMachine.vue";
import { usePoolsStore } from "@/stores/PoolsStore";
import PoolList from "@/components/pool/PoolList.vue";
import { i18n } from "@/i18n";
const { t } = i18n.global;
const poolsStore = usePoolsStore();

import { useOrganizationsStore } from "@/stores/OrganizationsStore";
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/stores/AuthStore";
import { useVirtualMachineStore } from "@/stores/VirtualMachineStore";
import PageTitle from "../components/ui/PageTitle.vue";
const { checkPermission } = useAuthStore();
const virtualMachineStore = useVirtualMachineStore();

const { pools } = storeToRefs(poolsStore);
// const { fetchVirtualMachines } = virtualMachineStore;
const { virtualMachines } = storeToRefs(virtualMachineStore);

const organizationsStore = useOrganizationsStore();

// clusterStore.fetchClusters();
const searchOrganization = ref("");

const filteredVms = computed(() => {
   return virtualMachines.value.filter((vm) => {
        return !vm?.poolCode;
    })
})


const organizationOptions = computed(() =>
  organizationsStore.organizations.map((organization) => ({
    value: organization.id,
    label: organization.zoho_customer_id + " / " + organization.name,
  }))
);

const freeVms = computed(() => {
  if (searchOrganization.value === "") {
    return filteredVms.value;
  }
  return filteredVms.value.filter((vm) => {
    return vm.organizationID === searchOrganization.value;
  });
});

const filteredPools = computed(() => {
  if (searchOrganization.value === "") {
    return pools.value;
  }
  return pools.value.filter((pool) => {
    return pool.organizationID === searchOrganization.value;
  });
});

const showVmTitle = computed(() => {
  return freeVms.value.length > 0 && filteredPools.value.length > 0;
});

const pageTitle = computed(() => {
  let title = null;

  if(!filteredPools.value.length && freeVms.value.length){
    title = t('label_virtual_machines');
  }
  else  title = t('label_resource_pools');
  
  return title;
})


// poolsStore.fetchPools();
</script>
<style scoped lang="postcss">
#searchOrganization {
  & > .base-select{
    display: flex;
    gap: 0.5rem;
    justify-content: end;
  }
}
</style>