<template>
    <div id="youCannotUseFordataFromMobile">
        <div class="d-flex align-items-center justify-content-center h-100 text-center">
        <div>
            <h1 class="">{{ $t("text_oops_problem")}}</h1>
            <h3>{{ $t("text_screen_too_small") }}</h3>
        </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>

</style>