<template>
  <form :key="formKey" class="space-y-4" @submit="onSubmit">
    <div class="flex flex-col lg:flex-row gap-4">
      <BaseSelect id="type" :label="$t('label_type')" name="type" :options="typeOptions"
        @change="resetForm({ values: { type: values.type } })" required />
      <BaseInput id="name" :label="$t('label_name')" name="key" :placeholder="$t(`placeholder_${values.type.toLowerCase()}_key`) || '@'" type="text" :required="['SRV', 'PTR'].includes(values.type)" />
      <BaseInput v-if="!['POOL'].includes(values.type)" id="value" :label="$t('label_value')" name="value" :placeholder="$t(`placeholder_${values.type.toLowerCase()}_value`)"
        :style="{ 'min-width': (['TXT', 'SPF', 'CNAME'].includes(values.type)) ? '60%' : '' }" type="text" required />
      <BaseSelect v-if="values.type == 'POOL' && poolListOptions.length > 0" id="pool" label="Available pools"
        name="value" :options="poolListOptions" required />
      <BaseInput v-if="['MX', 'SRV'].includes(values.type)" id="priority" :label="$t('label_priority')" name="priority"
        :placeholder="$t('placeholder_priority')" type="number" min="0" max="65535" required />
      <BaseInput v-if="['SRV'].includes(values.type)" id="weight" :label="$t('label_weight')" name="weight" :placeholder="$t('placeholder_weight')"
        type="number" min="0" max="65535" required />
      <BaseInput v-if="['SRV'].includes(values.type)" id="port" :label="$t('label_port')" name="port" :placeholder="$t('placeholder_port')"
        type="number" min="0" max="65535" required />
      <div class="shrink-0 w-[160px]">
        <BaseSelect id="ttl" label="TTL" name="ttl" :options="ttlList" required />
      </div>
    </div>
    <div class="flex gap-x-4 items-end">
      <BaseTextarea id="comment" :label="$t('label_comment')" name="description" :placeholder="$t('placeholder_comment')" />
    </div>
    <div class="flex justify-end items-center gap-6">
      <BaseButton size="sm" type="text" variant="secondary" @click="$emit('closeZoneAddRecordForm')">{{ $t('label_cancel') }}</BaseButton>

      <BaseButton formnovalidate :disabled="isSubmitting || !meta.valid" size="sm">{{ $t('label_add') }} record</BaseButton>
    </div>
  </form>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useForm } from "vee-validate";

import { useZonesStore } from "@/stores/ZonesStore";


import { i18n } from "@/i18n";
const { t } = i18n.global;

import useToastStore from "@/stores/toast";
import recordSchema from "@/validations/schemes/record";

import { useRoute } from "vue-router";
const { addToast } = useToastStore();
const { ttlList } = useZonesStore();
const formKey = ref(false);
const route = useRoute();

defineEmits(["closeZoneAddRecordForm"]);

const initialValues = {
  type: 'A',
  ttl: 300
}

const schema = recordSchema;

const { isSubmitting, handleSubmit, meta, resetForm, values, validate } = useForm(
  {
    validationSchema: schema,
    initialValues
  }
);

const { addRecord } = useZonesStore();

const zonesStore = useZonesStore();

const poolListOptions = computed(() => {
  return (
    zonesStore.poolList.map((pool) => {
      return { value: pool.id, label: pool.name };
    }) || []
  );
});

const types = [
  { value: "A", label: "A" },
  { value: "AAAA", label: "AAAA" },
  { value: "CNAME", label: "CNAME" },
  { value: "MX", label: "MX" },
  { value: "SRV", label: "SRV" },
  { value: "NS", label: "NS" },
  { value: "PTR", label: "PTR" },
  { value: "TXT", label: "TXT" },
  { value: "SPF", label: "SPF" },
];
const typeOptions = computed(() =>
  poolListOptions.value.length === 0
    ? types
    : [...types, { value: "POOL", label: "POOL" }]
);

onMounted(async () => {
  resetForm();
  await validate();
});

const onSubmit = handleSubmit(async (values) => {
  if (!values.key) values.key = '@';
  if (values.type == 'SRV') {
    const val = values.priority + ' ' + values.weight + ' ' + values.port + ' ' + values.value;
    values.value = val
    delete values.priority;
    delete values.weight;
    delete values.port;
  }
  await addRecord(route.params.id, { ...values })
    .then(() => {
      addToast({
        title: "Record " + t('text_create_generic_success'),
        variant: "success",
      });

      resetForm({ values: initialValues });
      //formKey.value = !formKey.value;
    })
    .catch((error) => {
      addToast({
        title: t('text_create_generic_failure') + 'record',
        content: error,
        variant: "danger",
      });
    });
});
</script>
