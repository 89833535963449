<template>
  <h2 class="text-xl font-semibold">Reset credentials</h2>
  <form class="w-full space-y-8" @submit="onSubmit">
    <div class="">
      <BasePasswordField id="password" label="Password" name="userPassword" placeholder="Password" :disabled="needSupport">
        <template #label-more>
          <div class="w-1/2 h-2.5 bg-zinc-100 rounded-full overflow-hidden">
            <password-meter :password="values.userPassword" @score="onScore" />
          </div>
        </template>
      </BasePasswordField>
    </div>
    <BasePasswordField id="confirmPassword" label="Confirm password" name="userConfirmPassword"
      placeholder="Confirm password"  :disabled="needSupport"/>
    <BaseButton formnovalidate :disabled="isSubmitting || !meta.valid || score < 0" class="block ml-auto">{{ $t('label_complete_reset') }}
    </BaseButton>
    <p v-if="needSupport" class="text-sm !text-red-500">
      {{ $t(error) }} <a class="cursor-pointer underline hover:text-neptune-400" @click.prevent="openWindow">{{ $t('label_support').toLowerCase() }}</a>
      {{ $t('text_or_go_to_page') }}  <RouterLink :to="{ name: 'HomePage' }"  class="cursor-pointer underline hover:text-neptune-400" >{{ $t('text_home_page') }}</RouterLink>
    </p>
    <p v-if="success" class="text-sm">
      {{ capitalizeFirst($t('text_go_to')) }} <RouterLink :to="{ name: 'LoginPage' }" class="cursor-pointer underline hover:text-neptune-400" >{{ $t('label_login') }}</RouterLink>
    </p>
  </form>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";
import { i18n } from "@/i18n";
const { t } = i18n.global;
import { useForm } from "vee-validate";
import { capitalizeFirst } from "@/utilities/helpers";

import PasswordMeter from "vue-simple-password-meter";

import { useAuthStore } from "@/stores/AuthStore";
import { useRoute, useRouter } from "vue-router";
import useToastStore from "@/stores/toast";
import {resetPasswordSchema} from "@/validations/schemes/auth";


const route = useRoute();
const router = useRouter();

const authStore = useAuthStore();

const { addToast } = useToastStore();

const error = ref(false);
const success = ref(false);

const schema = resetPasswordSchema;

const { isSubmitting, handleSubmit, meta, resetForm, values } = useForm({
  validationSchema: schema,
});

const score = ref(null);
const onScore = (payload) => {
  console.log(payload);
  score.value = payload.score;
};

const onSubmit = handleSubmit(async (values) => {
  try {
    error.value = false;
    await authStore.resetCredentials({
      ...values,
      resetToken: route.query.resetToken,
    });
    resetForm();
    success.value = true;
    addToast({
      title: t("title_reset_password_success"),
      variant: "success"
    })
    router.push({ name: "LoginPage" });
  } catch (err) {
    error.value = err;
  }
});

const needSupport = ref(false);


async function openWindow(){
  try {
    const url = "https://support.deepstone.cloud/";
    const options = '_blank'; // Per aprire in una nuova scheda o finestra
    window.open(url, options);
  } catch (error) {
    return;
  }


  }

onBeforeMount(async () => {
  try {
    let canPass = false; 
    if( route.query.registerToken ){
      try {
        await authStore.acceptInvitation({token: route.query.registerToken, email: route.query.email});
        canPass = true;
      }
      catch (err) {
        error.value = JSON.parse(err)?.invitation;
        addToast({
          title: t("title_accept_invitation_failed"),
          variant: "danger",
          content: err
        });
        if(err.includes("expired")) {
          needSupport.value = true;
        }
        if(err.includes("already_accepted")) {
          router.push({ name: "LoginPage" });
        }
      }
    }
    if(canPass && !error.value && route.query.resetToken){
      await authStore.validateResetToken({ resetToken: route.query.resetToken });
    }
  }
  catch (err) {

    error.value = err;

    addToast({
      title: t('text_reset_session_expired'),
      variant: "danger"
    });

    router.push({ name: "RecoveryPage" });
  }
})

</script>

<style lang="postcss">
.po-password-strength-bar {
  @apply h-full rounded-none mt-0 !important;
}
</style>
