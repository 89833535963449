<template>
  <div v-if="virtualMachineStore.summary"  class="flex justify-between items-center">
    <div class="space-y-2">
      <div class="flex gap-x-4">
        <h1 class="text-2xl font-bold text-zinc-800">
          {{ virtualMachineStore.summary.name }}
        </h1>
        <div class="flex gap-x-4">
			<BaseButton
				v-if="virtualMachineStore.summary.power_state == 'POWERED_ON' && checkPermission('power_off_virtual_machine')"
				variant="danger"
				size="sm"
				@click="changePowerStatus('off')"
				:tooltip="isEditing ? 'text_save_configuration_to_enable_power_actions' : '' "
				tooltipPosition="bottom"
				:disabled="isEditing || isLoading">
				{{ $t("label_power_off") }}
			</BaseButton>
			<BaseButton
				v-if="virtualMachineStore.summary.power_state == 'POWERED_ON' && checkPermission('reset_virtual_machine_power')"
				variant="warning"
				size="sm"
				@click="changePowerStatus('restart')"
				:tooltip="isEditing ? 'text_save_configuration_to_enable_power_actions' : '' "
				tooltipPosition="top"
				:disabled="isEditing || isLoading">
				{{ $t("label_power_reset") }}
			</BaseButton>
			<BaseButton
				v-if="virtualMachineStore.summary.power_state == 'POWERED_OFF' && checkPermission('power_on_virtual_machine')"
				variant="success"
				size="sm"
				@click="changePowerStatus('on')"
				:tooltip="isEditing ? 'text_save_configuration_to_enable_power_actions' : '' "
				tooltipPosition="bottom"
				:disabled="isEditing || isLoading">
				{{ $t("label_power_on") }}
			</BaseButton>
          <LoaderSpinner v-if="!isEditing && isLoading" />
        </div>
      </div>
      <div
        v-if="
          virtualMachineStore.summary.power_state == 'POWERED_OFF' ||
          virtualMachineStore.summary.power_state == 'POWERED_ON'
        "
        class="flex items-center gap-2"
      >
        <p class="font-bold">{{ $t("label_status") }}</p>
        <BaseTag
          :variant="
            virtualMachineStore.summary.power_state == 'POWERED_ON' ? 'success' : 'danger'
          "
          size="lg"
          :text="
            virtualMachineStore.summary.power_state == 'POWERED_ON'
              ? $t('label_running')
              : $t('label_stopped')
          "
          :icon="
            virtualMachineStore.summary.power_state == 'POWERED_ON'
              ? 'check_circle'
              : 'error'
          "
        />
      </div>
    </div>
    <BaseButton
      size="sm self-start"
      variant="primary"
      type="text"
      @click="router.go(-1)"
    > 
      {{ $t("label_go_back") }}
    </BaseButton>
  </div>
  <div v-if="virtualMachineStore.summary && virtualMachineStore.details" class="grid grid-cols-12 gap-4">
    <BaseBox class="col-span-6 flex items-center justify-start gap-12">
      <div class="flex flex-col gap-4 items-center justify-center" style="flex: 1 0 30%">
        <div class="flex items-center justify-center gap-4" >
          <component :is="osImage" class="w-24"/>
        </div>
      </div>
      <ul class="flex flex-col align-center justify-center col-span-6" style="flex: 1 0 70%">
        <li><strong>GUEST OS:</strong> {{ virtualMachineStore.summary.guest_OS }}</li>
        <li><strong>UUID:</strong> {{ virtualMachineStore.summary.instance_uuid }}</li>
        <li>
          <strong>HOSTNAME:</strong>
          {{ virtualMachineStore.summary.hostname }}
        </li>
        <li>
          <strong>IPv4: </strong>
          <span v-if="!getIpAsArray(virtualMachineStore.summary)?.length"></span>
          <span v-else-if="getIpAsArray(virtualMachineStore.summary)?.length === 1">{{ getIpAsArray(virtualMachineStore.summary)[0] }}</span>
          <a v-else @click="showAllIps(virtualMachineStore.summary)" class="cursor-pointer underline text-neptune-400">({{getIpAsArray(virtualMachineStore.summary).length}}x)</a>
        </li>
        <li>
          <strong>IPv6: </strong>
          <span v-if="!getIpAsArray(virtualMachineStore.summary, true)?.length"></span>
          <span v-else-if="getIpAsArray(virtualMachineStore.summary, true)?.length === 1">{{ getIpAsArray(virtualMachineStore.summary, true)[0] }}</span>
          <a v-else @click="showAllIps(virtualMachineStore.summary, true)" class="cursor-pointer underline text-neptune-400">({{getIpAsArray(virtualMachineStore.summary, true).length}}x)</a>
        </li>
        <li>
          <strong>NETWORK:</strong>
          {{ getNetworkAsStrings(virtualMachineStore.summary) }}
        </li>
        <li class="mt-2"> 
          <!-- TODO: usare il link quando sarà disponibile il kvm -->
          <!-- <BaseButton v-if="kvmUrl" tag="a" :href="kvmUrl" target="_blank">
            {{ $t("label_open_kvm_console") }}
          </BaseButton> -->
          <!-- <a class="hover:underline hover:text-neptune-400 text-lg font-semibold cursor-pointer" @click.prevent="showKvmModal">
            {{ $t("label_open_kvm_console") }}
          </a> -->

           <BaseButton @click="showKvmModal">
            {{ $t("label_open_kvm_console") }}
          </BaseButton> 
        </li>
      </ul> 
    </BaseBox>
	<VirtualMachineSingleForm
    :key="isEditing"
		v-if="checkPermission('edit_virtual_machine_resources')"
		:isEditing="isEditing"
		@toggleEditing="isEditing = !isEditing"
		@toggleSubmitting="isLoading = !isLoading"
		class="col-span-6"
	></VirtualMachineSingleForm>
  </div>
  <div class="grid grid-cols-12 gap-4">
    <BaseBox class="col-span-4 max-h-64">
      <h3 class="text-lg font-semibold mb-2">{{ $t("title_hardware_details") }}</h3>
      <!-- <ul v-if="virtualMachineStore.details" class="box overflow-y-scroll ml-0 max-h-full">
        <li class="flex flex-col gap-2 mr-2 last:mb-8" v-for="([key, value]) in hardwareDetails" :key="key">
          <strong>
            {{ key.replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase().split(".").join(" ").split("_").join(" ") }} :
          </strong>
          <pre>
            {{ value }}
          </pre>
        </li>
      </ul> -->
      {{ $t("text_not_enabled") }}.
    </BaseBox>
    <BaseBox class="col-span-8">
      <h3 class="text-lg font-semibold mb-2">{{ $t("title_activity_diary") }}</h3>
      <p>{{ $t("text_not_enabled") }}.</p>
    </BaseBox>
    <!-- <BaseBox class="col-span-12">
      <h3 class="text-lg font-semibold mb-2">{{ $t("label_statics") }}</h3>
      <p>{{ $t("text_not_enabled") }}.</p>
    </BaseBox> -->
	<VirtualMachineMonitoring class="col-span-12" ></VirtualMachineMonitoring>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { useVirtualMachineStore } from "@/stores/VirtualMachineStore";
import { useModalStore } from "@/stores/ModalStore";
import { useAuthStore } from "@/stores/AuthStore";
import { computed, onMounted, ref } from "vue";
import useToastStore from "@/stores/toast";
import { getIpAsStrings } from "@/utilities/helpers";
import { i18n } from "@/i18n";
import VirtualMachineSingleForm from "@/components/monitors/VirtualMachineSingleForm.vue";
import VirtualMachineMonitoring from "@/components/monitors/VirtualMachineMonitoring.vue";

import ConfirmModal from "@/components/ui/ConfirmModal.vue";
import ArchLogo from "@/components/icons/os/ArchLogo.vue";
import CentosLogo from "@/components/icons/os/CentosLogo.vue";
import DebianLogo from "@/components/icons/os/DebianLogo.vue";
import FedoraLogo from "@/components/icons/os/FedoraLogo.vue";
import RhelLogo from "@/components/icons/os/RhelLogo.vue";
import SuseLogo from "@/components/icons/os/SuseLogo.vue";
import UbuntuLogo from "@/components/icons/os/UbuntuLogo.vue";
import WindowsLogo from "@/components/icons/os/WindowsLogo.vue";
import ModalTable from "@/components/pool/ModalTable.vue";
import router from "@/routes";
import { getIpAsArray } from "@/utilities/helpers";

async function showAllIps(row, isV6 = false) {
  const allIps = getIpAsArray(row, isV6).map(el => ({ip: el}));
  await modalStore.openModal({
    title: t('label_all_ips'),
    component: ModalTable,
    props: {
      data: allIps,
      fields: ['ip']
    }
  })
}

const { t } = i18n.global;
const { addToast } = useToastStore();

const route = useRoute();
const virtualMachineStore = useVirtualMachineStore();
const modalStore = useModalStore();
const authStore = useAuthStore();

virtualMachineStore.fetchVirtualMachine(route.params.vmCode);

const { checkPermission } = authStore;

const isLoading = ref(false);
const isEditing = ref(false);

onMounted(async () => {
  window.scroll(0, 0);
})

const hardwareDetails = computed({
  get() {
    const selctedFields = [
      "boot",
      "cpu",
      "boot_devices",
      "scsi_adapters",
      "disks",
      "hardware",
      "sata_adapters",
      "memory",
      "identity",
      "instant_clone_frozen",
    ];

    return Object.entries(virtualMachineStore.details).filter(([key]) =>
      selctedFields.includes(key)
    );
  },
});

const kvmUrl = computed(() => {
  const domain = import.meta.env.VITE_KVM_DOMAIN;
  const path = import.meta.env.VITE_KVM_PATH;

  return `${domain}${path}/${virtualMachineStore.summary.code}`;
});

const osImage = computed(() => {
  let guest_OS = virtualMachineStore.summary.guest_OS;
  guest_OS = guest_OS.toLowerCase();
  let imagePath = "";

  switch (true) {
    case guest_OS.includes("windows"):
      imagePath = WindowsLogo;
      break;
    case guest_OS.includes("centos"):
      imagePath = CentosLogo;
      break;
    case guest_OS.includes("ubuntu"):
      imagePath = UbuntuLogo;
      break;
    case guest_OS.includes("rhel"):
      imagePath = RhelLogo;
      break;
    case guest_OS.includes("debian"):
      imagePath = DebianLogo;
      break;
    case guest_OS.includes("suse"):
      imagePath = SuseLogo;
      break;
    case guest_OS.includes("arch"):
      imagePath = ArchLogo;
      break;
    case guest_OS.includes("fedora"):
      imagePath = FedoraLogo;
      break;
    default:
      imagePath = UbuntuLogo;
  }

  return imagePath;
});

function getNetworkAsArray(row) {
  const nicsCodes = Object.keys(row.nics);
  const result = new Set();

  for (let i = 0; i < nicsCodes.length; i++) {
    const nicCode = nicsCodes[i];
    const nic = row.nics[nicCode];
    const network = nic.backing.network;

    if (network) {
      result.add(network);
    }
  }

  return Array.from(result);
}


function getNetworkAsStrings(row) {
  return getNetworkAsArray(row).join(", ");
}

async function showKvmModal() {
  await modalStore.openConfirmModal({
    title: t("title_kvm_console"),
    component: ConfirmModal,
    props: {
      content: t('text_kvm_coming_soon'),
      showConfirmButton: false,
      labelCancel: t('label_close'),
    },
  });
}

async function changePowerStatus(statusToSet) {
  try {
    let message = "";
    let modalMessage = "";
    if (statusToSet === "on") {
      isLoading.value = true;
      await virtualMachineStore.powerOn(route.params.vmCode);
      message = t("text_message_vm_power_on");
    }
    if (statusToSet === "off") {

      await modalStore.openConfirmModal({
        title: t('title_power_off_virtual_machine'),
        component: ConfirmModal,
        props: {
          content: t('text_power_off_virtual_machine'),
          isLoading
        },
      });
      
      isLoading.value = true;

      await virtualMachineStore.powerOff(route.params.vmCode);

      message = t("text_message_vm_power_off");
    }
    if (statusToSet === "restart") {

      await modalStore.openConfirmModal({
        title: t('title_restart_virtual_machine'),
        component: ConfirmModal,
        props: {
          content: t('text_restart_virtual_machine'),
          isLoading
        },
      });
      isLoading.value = true;

      await virtualMachineStore.powerReset(route.params.vmCode);
      message = t("text_message_vm_power_reset");
    }
    addToast({
      title: message,
      variant: "success",
    });
  } catch (error) {
    if(error)
      addToast({
        title: t("text_update_generic_failure"),
        variant: "danger",
        content: error,
      });
  }
  finally{
    isLoading.value = false;
    modalStore.closeModal();
  }
}
</script>

<style lang="postcss" scoped>
	.box {
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}
</style>

