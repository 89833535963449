<template>
	<BaseBox v-if="virtualMachineStore.summary && virtualMachineStore.details" >
		<form @submit="onSubmit" class="grid grid-cols-2 gap-5">
			<!-- CPU row -->
			<div class="flex items-center gap-4">
				<IconText icon="memory" icon-size="text-5xl" />
				<span class="text-lg font-medium">vCPU</span>
			</div>
			<div class="text-center">
				<div  class="flex justify-center flex-wrap">
					<BaseInput
						:disabled="!isEditing"
						id="cpu"
						name="cpu"
						type="text"
						:isStepBtn="true"
						:min="1"
						:max="20"
						:step="1"
						required
						suffix="CPU"
						suffixClass="min-w-12 flex items-center text-sm font-medium"
						:initialValue="parseInt(virtualMachineStore.summary.cpu)"
					/>
					<span v-if="ghz" class="mr-12 text-center justify-center text-sm font-medium">{{ ghz }} GHz</span>
				</div>
				<!-- <span v-else class="text-lg font-medium">{{ virtualMachineStore.summary.cpu }}</span> -->
			</div>
			<!-- RAM row -->
			<div class="flex items-center gap-4">
				<IconText icon="memory_alt" icon-size="text-5xl"/>
				<span class="text-lg font-medium">RAM</span>
			</div>
			<div class="text-center">
				<BaseInput
					:disabled="!isEditing"
					id="memory"
					name="memory"
					type="text"
					:isStepBtn="true"
					:min="1"
					:max="64"
					:step="1"
					suffix="GB"
					suffixClass="min-w-12 flex  items-center  justify-center text-sm font-medium"
					required
					:initialValue="parseInt(convertToHigherUnit(virtualMachineStore.summary.memory_mib, 'MB', false))"
				/>
				<!-- <span v-else class="text-lg font-medium">{{ convertToHigherUnit(virtualMachineStore.summary.memory_mib, 'MB') }}</span> -->
			</div>
			<!-- Storage row -->
			<div class="flex items-center gap-2" style="width: 101%;">
				<IconText icon="database" icon-size="text-5xl"/>
				<span class="text-lg font-medium">STORAGE</span>
			</div>
			<div class="flex flex-col gap-4 justify-start">
				<!-- <BaseInput
					v-if="isEditing"
					id="storage"
					name="storage"
					type="text"
					:isStepBtn="true"
					:min="20"
					:max="500"
					:step="1"
					suffix="GB"
					required
					:initialValue="parseInt(convertToHigherUnit(virtualMachineStore.summary.disk.diskCode.capacity_mib, 'B', false))"
				/>
				<span v-else class="text-lg font-medium">{{ convertToHigherUnit(virtualMachineStore.summary.disk.diskCode.capacity_mib, "B") }}</span> -->
				<!-- <div v-if="disks?.length" class="flex flex-col gap-2" >
					<div v-for="disk in disks" :key="disk" class="flex gap-2 items-start">
						<IconText icon="hard_disk" />
						<p class="font-bold">{{ disk.label }}</p>
						<ElementWithUnit :value="disk.capacity" unit="B" converter="data" />
					</div>
				</div> -->
				<BaseButton 
					variant="primary"
					size="sm"
					target="_blank"
					class="max-h-10 max-w-32"
					@click.prevent="openWindow"
				>
					{{ t("label_edit_storage") }}
				</BaseButton>
				<div v-if="disks?.length" class="flex flex-col gap-2" >
					<div v-for="disk in disks" :key="disk" class="flex gap-2 items-start">
						<IconText icon="hard_disk"/>
						<p class="font-bold">{{ disk.label }}</p>
						<ElementWithUnit :value="disk.capacity" unit="B" converter="data" />
					</div>
				</div>
			</div>
			
			<div class="col-span-2 flex items-center justify-start gap-4">
				<LoaderSpinner v-if="isSubmitting" />
				<BaseButton
					v-if="isEditing"
					variant="secondary"
					size="sm"
					@click="$emit('toggleEditing')"
					:disabled="virtualMachineStore.summary.power_state == 'POWERED_ON'">
					{{ t("label_cancel") }}
				</BaseButton>
				<BaseButton
					v-if="isEditing"
					variant="primary"
					size="sm"
					:disabled="isSubmitting || !meta.valid || !hasEditConf">
					{{ t("label_save_configuration") }}
				</BaseButton>
				<BaseButton
					v-if="!isEditing"
					variant="primary"
					size="sm"
					type="a"
					:tooltip="(virtualMachineStore.summary.power_state == 'POWERED_ON') ? 'Power Off VM to edit settings' : '' "
					@click="$emit('toggleEditing')"
					:disabled="virtualMachineStore.summary.power_state == 'POWERED_ON'">
					{{ t("label_edit_vm_settings") }}
				</BaseButton>
			</div>
		</form>
	</BaseBox>
</template>

<script setup>
	import { useVirtualMachineStore } from "@/stores/VirtualMachineStore";
	import { useModalStore } from "@/stores/ModalStore";
	import { useAuthStore } from "@/stores/AuthStore";
	import { convertToHigherUnit } from "@/utilities/helpers";
	import { computed, ref } from "vue";
	import useToastStore from "@/stores/toast";
	import { i18n } from "@/i18n";
	import { useForm } from "vee-validate";
	import * as yup from 'yup';
	import { capitalizeFirst } from "@/utilities/helpers";
	import ConfirmModal from "@/components/ui/ConfirmModal.vue";
	import { convertStorageUnit } from "@/utilities/helpers";
	import LoaderSpinner from "@/components/ui/LoaderSpinner.vue";
	import ElementWithUnit from "@/components/ui/ElementWithUnit.vue";

	const { t } = i18n.global;
	const { addToast } = useToastStore();

	const virtualMachineStore = useVirtualMachineStore();
	const modalStore = useModalStore();
	const authStore = useAuthStore();


	const { checkPermission } = authStore;
	const error = ref("");

	const emit = defineEmits(["toggleEditing", "toggleSubmitting"]);

	const { initialValues }   = defineProps({
		// initialValues: {
		// 	type: Object,
		// 	default: () => ({}),
		// },
		isEditing: {
			type: Boolean,
			default: false
		}
	});


	const vmSchema = yup.object({
		cpu: yup
			.string()
			.digitsWithMinLength(1)
			.stringWithMinValue(1)
			.stringWithMaxValue(20)
			.required(),
		memory: yup
			.string()
			.digitsWithMinLength(1)
			.stringWithMinValue(1)
			.stringWithMaxValue(64)
			.required(),
		// storage: yup
		// 	.string()
		// 	.digitsWithMinLength(1)
		// 	.stringWithMinValue(20)
		// 	.stringWithMaxValue(500)
		// 	.required(),
	});

	const { isSubmitting, handleSubmit, meta, values } = useForm({
		validationSchema: vmSchema,
		initialValues:  initialValues || {
		    cpu: 0,
		    memory: 0,
		    storage: 0,
		},
	});

	const ghz = computed(() => {
		const conv_rate = parseFloat(import.meta.env.VITE_CONVERSION_RATE);
		const cpu = parseFloat(values.cpu);

		if (isNaN(cpu) || isNaN(conv_rate)) {
			return '-';
		}

		return (cpu * conv_rate).toFixed(1);
	});

	const hasEditConf = computed(() => {
		const vmToUpdate = {
			cpu: values.cpu,
			memory: convertStorageUnit(values.memory, 'MB', 'GB'),
			storage: convertStorageUnit(values.storage, 'B', 'GB'),
		}
		return virtualMachineStore.summary.cpu != vmToUpdate.cpu ||
			virtualMachineStore.summary.memory_mib != vmToUpdate.memory ||
			virtualMachineStore.summary.disk.diskCode.capacity_mib != vmToUpdate.storage;
	});

	const disks = computed(() => {
		const disks = Object.keys(virtualMachineStore.details.disks);
		const res = [];
		if (disks.length > 0) {
			disks.forEach((k) => {
				res.push(virtualMachineStore.details.disks[k]);
			})
		}
		return res;

	})

	const onSubmit = handleSubmit(async (values) => {
		try {
			const castValues = vmSchema.cast(values);

			isSubmitting.value = true;

			const vmToUpdate = {
				vcpu: parseInt(castValues.cpu),
				memory_mib: convertStorageUnit(castValues.memory, 'MB', 'GB'),
				//storage_mib: convertStorageUnit(castValues.storage, 'B', 'GB'), TODO: rimuovere commento quando definita l'api
				storage_mib: virtualMachineStore.summary.disk.diskCode.capacity_mib
			}

			emit('toggleSubmitting');

			if (virtualMachineStore.summary.disk.diskCode.capacity_mib != vmToUpdate.storage_mib){
				await modalStore.openConfirmModal({
					title: t('label_warning'),
					component: ConfirmModal,
					props: {
						error,
						content: t('alert_storage_operations_required'),
					},
				});
			}

			await virtualMachineStore.resizeVM(virtualMachineStore.summary.code, vmToUpdate);

			addToast({
				title: capitalizeFirst(t("text_update_generic_success")),
				content: t('text_update_success'),
				variant: "success",
			});
		} catch (err) {
			if(err){
				addToast({
					title: `${t("text_update_generic_failure")}`,
					content: error.value || err,
					variant: "danger",
				});
			}
		} finally {
			emit('toggleEditing');
			emit('toggleSubmitting');
			isSubmitting.value = false;
			await modalStore.closeModal();
		}
	});

	async function openWindow(){
		try {
			await modalStore.openConfirmModal({
				title: t('label_edit_storage'),
				component: ConfirmModal,
				props: {
					content: t('text_storage_edit_ask_to_support'),
					labelConfirm: t('label_contact'),
				},
			})
			const url = "https://support.deepstone.cloud/";
			const options = '_blank'; // Per aprire in una nuova scheda o finestra
			window.open(url, options);
		} catch (error) {
			return;
		}


    }

</script>

<style lang="postcss" scoped>
	.box {
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}
</style>
