<template>
  <BaseBox class="space-y-4">
    <h2 class="text-2xl font-bold">{{ $t("title_database_backups") }}</h2>
    <p>{{ $t("text_database_backup_description") }}</p>

    <Table :data="clusterStore.backupList">
      <template #head>
        <tr>
          <th>{{ $t("label_type") }}</th>
          <th>{{ $t("label_date") }}</th>
          <th>{{ $t("label_size") }}</th>
          <th class="end">{{ $t("label_actions") }}</th>
        </tr>
      </template>
      <template #body>
        <template v-for="backup in clusterStore.backupList" :key="backup.id">
          <tr>
            <td>
              {{ backup.type }}
            </td>
            <td>
              {{ formatDate(backup.date) }}
            </td>
            <td>
              <ElementWithUnit :value="backup.size_mib" unit="MB" converter="data" />
            </td>

            <td class="end">
              <BaseButton
                class="!inline-flex"
                icon="download"
                tooltip="Download"
                size="xs"
                variant="secondary"
                @click="handleDownloadBackup(backup.id)"
              />
            </td>
          </tr>
        </template>
      </template>

      <template #no-data>
        <p class="text-sm text-center font-semibold">{{ $t("text_no_backups_found") }}</p>
      </template>
    </Table>
    <AlertMessage v-if="error" status="error">{{ error }}</AlertMessage>
  </BaseBox>
</template>

<script setup>
import { ref } from "vue";
import { useClusterStore } from "@/stores/ClusterStore";
import { formatDate } from "@/utilities/helpers";
import ElementWithUnit from "@/components/ui/ElementWithUnit.vue";

const error = ref("");

const clusterStore = useClusterStore();

async function handleDownloadBackup(backupID) {
  try {
    await clusterStore.downloadBackup(clusterStore.cluster.id, backupID);
  } catch (err) {
    error.value = err;
  }
}
</script>
