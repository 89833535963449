import { defineStore } from "pinia";
import { virtualMachine } from "@/api/base";
import { usePoolsStore } from "./PoolsStore";

export const useVirtualMachineStore = defineStore("VirtualMachineStore", {
  // state
  state: () => ({
    summary: null,
    details: null,
    virtualMachines: [],
  }),
  // getters
  getters: {
    getFreeVms: (state) => {
      return state.virtualMachines.filter((vm) => {
        return !vm.poolCode || !vm.resourcePoolID;
      })
    }
  },
  // actions
  actions: {
    async fetchVirtualMachine(code) {
      const { virtualMachineSummary, virtualMachineDetails } =
        await virtualMachine.read(code);
      this.summary = virtualMachineSummary;
      this.details = virtualMachineDetails;
    },
    async powerOn(code) {
      await virtualMachine.powerOn(code);
      this.summary.power_state = "POWERED_ON";
      await this.fetchVirtualMachines();
      await usePoolsStore().fetchPools();
    },
    async powerOff(code) {
      await virtualMachine.powerOff(code);
      this.summary.power_state = "POWERED_OFF";
      await this.fetchVirtualMachines();
      await usePoolsStore().fetchPools();
    },
    async powerReset(code) {
      await virtualMachine.powerReset(code);
      await this.fetchVirtualMachines();
      await usePoolsStore().fetchPools();
    },
    async resizeVM(code, payload) {
        await virtualMachine.resize(code, payload);
        await this.fetchVirtualMachines();
        await usePoolsStore().fetchPools();
        await this.fetchVirtualMachine(code);
    },
    async fetchVirtualMachines() {
      const { virtualMachines } = await virtualMachine.readAll();
      this.virtualMachines = virtualMachines;
      return virtualMachines;
    },
  },
});
