<template>
	<div class="grafana-chart-container">
		<iframe
			v-if="url"
			:key="iframeKey"
			:src="url"
			width="100%"
			height="600px"
			frameborder="0"
			allowfullscreen
			referrerpolicy="origin"
		></iframe>
		<p v-else>{{ $t("text_loading_data") }}</p>
	</div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

// Props
defineProps({
	url: {
		type: String,
		default: '',
	},
	title: {
		type: String,
		default: null,
	},
});

// State
const iframeKey = ref(0);

// Refresh logic
onMounted(() => {
	const refreshInterval = setInterval(() => {
		console.log('Refreshing Grafana chart...');
		iframeKey.value++; // Cambia il valore del key per forzare il refresh
	}, 60000); // Ogni minuto (60000 ms)

	onUnmounted(() => {
		clearInterval(refreshInterval); // Pulisci l'intervallo quando il componente viene distrutto
	});
});
</script>

<style lang="postcss" scoped>
.grafana-chart-container {
	iframe {
		@apply rounded border-none max-w-full max-h-96;
	}
}
</style>
