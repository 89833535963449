<template>
    <p :class="valueClass">
        {{prefix}} {{ converted }} 
        <span v-if="withUnit" :class="`text-[${parseInt(unitSizePx)}px] p-0 m-0`"> {{ convertedUnit }}</span> 
    </p>
</template>

<script setup>
import { convertToHigherUnit } from "@/utilities/helpers";
import { computed, ref } from "vue";

const props = defineProps({
    prefix: {
        type: String,
        default: ""
    },
    value: {
        type: [Number, String],
        required: true
    },
    valueClass: {
        type: String,
        default: "font-bold"
    },
    unit: {
        type: String,
    },
    unitClass: {
        type: String,
        default: "text-[12px] p-0 m-0"
    },
    unitSizePx: {
        type: Number,
        default: 12
    },
    withUnit: {
        type: Boolean,
        default: true
    },
    converter: {
        type: String,
        default: null
    }
})

const { value, unit, converter } = props

const convertedUnit = ref('');

const converted = computed(() => {
    if(!unit) return value;
    if(converter == 'data') {
        const res = convertToHigherUnit(value, unit);
        convertedUnit.value = res.split(" ")[1];
        return res.split(" ")[0];
    }
    else {
        convertedUnit.value = unit;
        return value;
    }
})

</script>

<style lang="scss" scoped>

</style>