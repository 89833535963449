<template>
  <BaseBox class="space-y-4">
    <div class="flex items-center justify-between w-full gap-3">
      <h3 v-if="withTitle" class="text-lg font-semibold py-1">{{ $t('label_virtual_machines') }}</h3>
      <SearchBar class="w-80 mr-auto" @on-search="(value) => (search = value.toLowerCase())" />
    </div>
    <Table :data="rows" :searching="isSearching">
      <template #head>
        <tr>
          <th>Status</th>
          <th>{{ $t('label_name') }}</th>
          <th>{{ $t('label_memory') }}</th>
          <th>CPU</th>
          <th>{{ $t('label_disk') }}</th>
          <th>OS</th>
          <th>IPv4</th>
          <th>IPv6</th>
        </tr>
      </template>
      <template #body>
        <tr v-for="row in rows" :key="row.id">
          <td>
            <BaseTag v-if="row.power_state == 'POWERED_ON'" variant="success" size="md" :text="$t('label_running')" />
            <BaseTag v-else variant="danger" size="md" :text="$t('label_stopped')" />
          </td>
          <td>
            <RouterLink :to="{
                name: 'VirtualMachineSinglePage',
                params: { poolID: props.poolId, vmCode: row.vmCode },
              }" class="hover:underline hover:text-neptune-400">{{ row.name }}</RouterLink>
          </td>
          <td> <ElementWithUnit :value="row.memory.size_MiB" unit="MB" converter="data" /></td>
          <td> <ElementWithUnit :value="row.cpu.count" :unit="'vCpu'" /></td>
          <td>
            <div v-if="getDisks(row).length < 1"></div>
            <div v-else-if="getDisks(row).length === 1"> <ElementWithUnit :value="getDisks(row)[0].capacity" :unit="getDisks(row)[0].unit" /> </div>
            <a v-else @click.prevent="showAllDisks(row)" class="cursor-pointer underline text-neptune-400">({{getDisks(row).length}}x)</a>
          </td>
          <td>{{ row.guest_OS }}</td>
          <td>
              <div v-if="!getIpAsArray(row)?.length"></div>
              <div v-else-if="getIpAsArray(row)?.length === 1">{{ getIpAsArray(row)[0] }}</div>
              <a v-else @click="showAllIps(row)" class="cursor-pointer underline text-neptune-400 text-neptune-400 ">({{getIpAsArray(row).length}}x)</a>
           </td>
          <td>
              <div v-if="!getIpAsArray(row, true)?.length"></div>
              <div v-else-if="getIpAsArray(row, true)?.length === 1">{{ getIpAsArray(row, true)[0]}}</div>
              <a v-else @click="showAllIps(row, true)" class="cursor-pointer underline text-neptune-400">({{ getIpAsArray(row, true).length }}x)</a>
          </td>
        </tr>
      </template>
    </Table>
  </BaseBox>
</template>

<script setup>
import { ref, computed } from "vue";
import { getIpAsArray } from "@/utilities/helpers";
import { RouterLink } from "vue-router";
import { useModalStore } from "@/stores/ModalStore";
import { translate as t } from "@/i18n";
import ModalTable from "@/components/pool/ModalTable.vue";
import ElementWithUnit from "@/components/ui/ElementWithUnit.vue";
import { convertToHigherUnit } from "@/utilities/helpers";

const modalStore = useModalStore();
//import { getTotalCapacity } from "@/utilities/helpers";
const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  poolId: {
    type: [Number, String],
  },
  withTitle: {
    type: Boolean,
    default: true,
  },
});

const search = ref("");

const isSearching = computed(() => {
  return search.value ? true : false;
});

const getDisks = (row, isTable = false) => {
  const disksKeys = Object.keys(row.disks);
  const disks = disksKeys.map((key) => {
    const conv = row?.disks[key]?.capacity ? convertToHigherUnit(row.disks[key].capacity, "B") : '0 GB';
    const unit = conv.split(' ')[1];
    const capacity = conv.split(' ')[0];
    return {
        ...row.disks[key], 
        id: key , 
        capacity: isTable ? conv : capacity,
        unit
    }
  })
  return disks;
};

async function showAllDisks(row) {
  const allDisks = getDisks(row, true);
  await modalStore.openModal({
    title: t('label_all_disks'),
    component: ModalTable,
    props: {
      data: allDisks,
      fields: ["label", "capacity"],
    }
  })
}


async function showAllIps(row, isV6 = false) {
  const allIps = getIpAsArray(row, isV6).map(el => ({ip: el}));
  await modalStore.openModal({
    title: t('label_all_ips'),
    component: ModalTable,
    props: {
      data: allIps,
      fields: ['ip']
    }
  })
}

const rows = computed(() => {
  return props.data.filter(
    (row) =>{
      if(row && (row.name?.toLowerCase().includes(search.value) ||
      row.guest_OS?.toLowerCase().includes(search.value) ||
      getIpAsArray(row)?.includes(search.value))) return row;
      
      }
  );

});
</script>
