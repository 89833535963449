import { createRouter, createWebHistory } from "vue-router";
// Middlewares
import middlewarePipeline from "./middlewares";
import auth, { permissions } from "./middlewares/auth";

// Pages
import HomePage from "../pages/HomePage.vue";
import DashboardPage from "../pages/DashboardPage.vue";
import ZonesPage from "../pages/ZonesPage.vue";
import ZoneSinglePage from "../pages/ZoneSinglePage.vue";
import ZoneSinglePageIndex from "../pages/ZoneSinglePageIndex.vue";
import ZonePoolCreatePage from "../pages/ZonePoolCreatePage.vue";
import ZonePoolEditPage from "../pages/ZonePoolEditPage.vue";
import MonitorPage from "../pages/MonitorPage.vue";
import PoolPage from "../pages/PoolPage.vue";
import PoolSinglePage from "../pages/PoolSinglePage.vue";
import VirtualMachineSinglePage from "../pages/VirtualMachineSinglePage.vue";
import ClusterPage from "../pages/ClusterPage.vue";
import ClusterSinglePage from "../pages/ClusterSinglePage.vue";
import ClusterSinglePageIndex from "../pages/ClusterSinglePageIndex.vue";
import ClusterConfigurationFile from "../pages/ClusterConfigurationFile.vue";
import ClusterConfiguration from "../pages/ClusterConfiguration.vue";
import ExpiredResetLandingPage from "../pages/ExpiredResetLandingPage.vue";
import LoryPage from "../pages/LoryPage.vue";
import ProfilePage from "../pages/ProfilePage.vue";
import BillingPage from "../pages/BillingPage.vue";
import OrganizationPage from "../pages/OrganizationPage.vue";
import OrganizationListPage from "../pages/OrganizationListPage.vue";
import UserListPage from "../pages/UserListPage.vue";


// Auth Pages
import LoginPage from "../pages/LoginPage.vue";
import RecoveryPage from "../pages/RecoveryPage.vue";
import ResetCredentialsPage from "../pages/ResetCredentialsPage.vue";

import { useAuthStore } from "@/stores/AuthStore";
import { usePoolsStore } from "@/stores/PoolsStore";
import { useUserStore } from "@/stores/UserStore";
import { useZonesStore } from "@/stores/ZonesStore";
import { useModalStore} from "@/stores/ModalStore";
import { useVirtualMachineStore} from "@/stores/VirtualMachineStore";
import UnauthorizedLandingPage from "../pages/UnauthorizedLandingPage.vue";
import MonitorMetricsPage from "../pages/MonitorMetricsPage.vue";
import StatisticsPage from "../pages/StatisticsPage.vue";
import TooSmallDevicePage from "../pages/TooSmallDevicePage.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "HomePage",
      component: HomePage,
      meta: {
        layout: "auth",
        breadcrumb: [
          {
            text: 'label_home',
          },
        ],
      },
    },
    {
      path: "/dashboard",
      name: "DashboardPage",
      component: DashboardPage,
      meta: {
        permission: "view_dashboard_page",
        layout: "default",
        middleware: [auth],
        breadcrumb: [
          {
            text: "label_dashboard",
          },
        ],
      },
    },
    {
      path: "/profile",
      name: "ProfilePage",
      component: ProfilePage,
      meta: {
        // permission: "view_profile_page",
        layout: "default",
        middleware: [auth],
        breadcrumb: [
          {
            text: "label_profile",
          },
        ],
      },
    },
    {
      path: "/billing",
      name: "BillingPage",
      component: BillingPage,
      meta: {
        // permission: "view_billing_page",
        layout: "default",
        middleware: [auth],
        breadcrumb: [
          {
            text: "label_billing",
          },
        ],
      },
    },
    {
      path: "/organization",
      name: "OrganizationPage",
      component: OrganizationPage,
      meta: {
        // permission: "view_organization_page",
        layout: "default",
        middleware: [auth],
        breadcrumb: [
          {
            text: "label_organization",
          },
        ],
      },
    },
    {
      path: "/organization-list",
      name: "OrganizationListPage",
      component: OrganizationListPage,
      meta: {
        permission: "*",
        layout: "default",
        middleware: [auth, permissions],
        breadcrumb: [
          {
            text: "label_organization_list",
          },
        ],
      },
    },
    {
      path: "/user-list",
      name: "UserListPage",
      component: UserListPage,
      meta: {
        permission: "*",
        layout: "default",
        middleware: [auth, permissions],
        breadcrumb: [
          {
            text: 'text_user_list',
          },
        ],
      },
    },
    {
      path: "/zones",
      name: "ZonesPage",
      component: ZonesPage,
      meta: {
        permission: "view_zones_page",
        layout: "default",
        middleware: [auth, permissions],
        breadcrumb: [
          {
            text: "label_zones",
          },
        ],
      },
    },
    {
      path: "/zones/:id",
      name: "ZoneSinglePage",
      component: ZoneSinglePage,
      meta: {
        permission: "view_zone_single_page",
        layout: "default",
        middleware: [auth, permissions],
      },
      beforeEnter: async (to) => {
        const { zone, fetchZone } = useZonesStore();
        const props = { zone: zone.value, hash: '' }
        if (!zone.value) {
          props.zone = await fetchZone(to.params.id);
        }
        if (to.hash) {
          props.hash = to.hash.replace('#', '');
        }
        //await validateZone(to.params.id);
        to.meta.props = props;
      },
      children: [
        {
          path: "",
          name: "ZoneSinglePageIndex",
          component: ZoneSinglePageIndex,
          meta: {
            breadcrumb(route) {
              const zoneId = route.params.id;

              return [
                {
                  text: "label_zones",
                  to: { name: "ZonesPage" },
                },
                {
                  text: zoneId,
                },
              ];
            },
          }
        },
        {
          path: "pool/create",
          name: "ZonePoolCreatePage",
          component: ZonePoolCreatePage,
          meta: {
            permission: "view_create_pool_page",
            middleware: [permissions],
            breadcrumb(route) {
              const zoneId = route.params.id;

              return [
                {
                  text: "label_zone",
                  to: { name: "ZonesPage" },
                },
                {
                  text: zoneId,
                  to: { name: "ZoneSinglePageIndex", params: { id: zoneId } },
                },
                { text: "Create pool" },
              ];
            },
          },
        },
        {
          path: "pool/:poolID/edit",
          name: "ZonePoolEditPage",
          component: ZonePoolEditPage,
          meta: {
            permission: "view_edit_pool_page",
            middleware: [permissions],
            breadcrumb(route) {
              const zoneId = route.params.id;
              const poolId = route.params.poolID;

              return [
                {
                  text: "label_zone",
                  to: { name: "ZonesPage" },
                },
                {
                  text: zoneId,
                  to: { name: "ZoneSinglePageIndex", params: { id: zoneId } },
                },
                { text: `Edit pool ${poolId}` },
              ];
            },
          },
        },
      ],
    },
    {
      path: "/monitor/:id?",
      name: "MonitorPage",
      component: MonitorPage,
      meta: {
        permission: "view_monitor_page",
        layout: "default",
        middleware: [auth, permissions],
        breadcrumb: [
          {
            text: "label_monitor",
          },
        ],
      },
    },
    {
      path: "/pool",
      name: "PoolPage",
      component: PoolPage,
      beforeEnter: async () => {
        const {fetchVirtualMachines} = useVirtualMachineStore();
        await fetchVirtualMachines();
      },
      meta: {
        permission: "view_resource_pool_page",
        layout: "default",
        middleware: [auth, permissions],
        breadcrumb: [
          {
            text: "label_resource_pools",
          },
        ],
      },
    },
    {
      path: "/pool/:id",
      name: "PoolSinglePage",
      component: PoolSinglePage,
      meta: {
        permission: "view_resource_pool_single_page",
        layout: "default",
        middleware: [auth, permissions],
        breadcrumb(route) {
          const resPoolID = route.params.id;
          const { pool, pools } = usePoolsStore();


          let name = '';

          if (pool?.name) {
            name = pool.name;
          }
          else if (pools && pools.length > 0) {
            const index = pools.findIndex((p) => p.id == resPoolID)
            if (index >= 0) {
              name = pools[index].name;
            }
          }
          else {
            name = resPoolID;
          }

          return [
            {
              text: "label_resource_pools",
              to: { name: "PoolPage" },
            },
            {
              text: name,
            },
          ];
        },
      },
    },
    {
      path: "/pool/:poolID/vm/:vmCode",
      name: "VirtualMachineSinglePage",
      component: VirtualMachineSinglePage,
      meta: {
        permission: "view_vm_page",
        layout: "default",
        middleware: [auth, permissions],
      },
    },
    {
      path: "/cluster",
      name: "ClusterPage",
      component: ClusterPage,
      meta: {
        permission: "view_cluster_page",
        layout: "default",
        middleware: [auth, permissions],
        breadcrumb: [
          {
            text: "label_deep",
          },
        ],
      },
    },
    {
      path: "/cluster/:id",
      name: "ClusterSinglePage",
      component: ClusterSinglePage,
      children: [
        {
          path: "",
          name: "ClusterSinglePageIndex",
          component: ClusterSinglePageIndex,
        },
        {
          path: "configuration-file",
          name: "ClusterConfigurationFile",
          component: ClusterConfigurationFile,
          meta: {
            permission: "view_cluster_configuration_file_page",
            middleware: [permissions],
          }
        },
        {
          path: "configuration",
          name: "ClusterConfiguration",
          component: ClusterConfiguration,
          meta: {
            permission: "view_cluster_configuration_page",
            middleware: [permissions],
          }
        },
      ],
      meta: {
        permission: "view_cluster_single_page",
        layout: "default",
        middleware: [auth, permissions],
        breadcrumb: [
          {
            text: "label_deep",
          },
        ],
      },
    },
    {
      path: "/statistics",
      name: "StatiticsPage",
      component: StatisticsPage,
      meta: {
        permission: "view_statitics_page",
        layout: "default",
        middleware: [auth, permissions],
        breadcrumb: [
          {
            text: "label_statitics",
          },
        ],
      },
      children:[
        {
          path: "monitor/:id",
          name: "MonitorMetricsPage",
          component: MonitorMetricsPage,
          meta: {
            permission: "view_statitics_monitor_page",
            layout: "default",
            middleware: [auth, permissions],
            breadcrumb: [
              {
                text: "label_monitor_metrics",
              },
            ],
          },
        },
      ]
    },
    {
      path: "/lory",
      name: "LoryPage",
      component: LoryPage,
      meta: {
        permission: "view_lory_page",
        layout: "default",
        middleware: [auth, permissions],
        breadcrumb: [
          {
            text: "label_lory",
          },
        ],
      },
    },
    {
      path: "/login",
      name: "LoginPage",
      component: LoginPage,
      meta: {
        layout: "auth",
      },
    },
    {
      path: "/recovery",
      name: "RecoveryPage",
      component: RecoveryPage,
      meta: {
        layout: "auth",
      },
    },
    {
      path: "/reset-credentials",
      name: "ResetCredentialsPage",
      component: ResetCredentialsPage,
      meta: {
        layout: "auth",
      },
    },
    {
      path: "/expired-reset-token",
      name: "ExpiredResetLandingPage",
      component: ExpiredResetLandingPage,
      meta: {
        layout: "auth",
      },
    },
    {
      path: "/unauthorized",
      name: "UnauthorizedPage",
      component: UnauthorizedLandingPage,
      meta: {
        layout: "auth",
      },
    },
    {
      path: "/unsupported",
      name: "UnsupportedPage",
      component: TooSmallDevicePage,
      meta: {
        layout: "auth",
      }
    }
  ],
});

router.beforeEach(async (to, from, next) => {

  const userStore = useUserStore();
  const authStore = useAuthStore();
  const modalStore = useModalStore();
  const { isOpen } = modalStore;

  if(isOpen) return next(false);

  const storageUser = localStorage.getItem("UserStore");

  if (storageUser) {
    let stUsr = JSON.parse(storageUser);
    if (stUsr.user?._id && userStore.user._id !== stUsr.user._id) {
      userStore.addUser(stUsr.user);
      localStorage.setItem("AuthStore", JSON.stringify({ auth_token: authStore.auth_token, lang: authStore.lang }));
    }
  }

  const allowedUnprotectedRoutes = [
    "HomePage",
    "LoginPage",
    "RecoveryPage",
    "ResetCredentialsPage",
    "UnauthorizedLandingPage",
  ]

  if (allowedUnprotectedRoutes.includes(to.name) && authStore.isLoggedIn) {
      const name = (to.name == "ResetCredentialsPage") ? "ExpiredResetLandingPage" : "DashboardPage";
      return next({ name });
  }

  if (!to.meta.middleware) {
    return next();
  }

  const middleware = to.meta.middleware;

  const context = {
    to,
    from,
    next,
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
