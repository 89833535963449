import { defineStore } from "pinia";
import { user as userApi } from "@/api/base";
import { loadTranslations } from "@/i18n";
export const useUserStore = defineStore("UserStore", {
	// state
	state: () => ({
		user: {},
		usersList: [],
		mode: null,
		lang: 'en',
		sidebarCollapsed: false,
	}),
	// getters
	getters: {
		isAdmin: (state) => {
			return state.user.role === "administrator";
		}
	},
	// actions
	actions: {
		addUser(payload) {
			this.user = payload;
		},
		toggleSidebarCollapsed() {
			this.sidebarCollapsed = !this.sidebarCollapsed;
		},
		setLightMode() {
			this.mode = "light";
			document.documentElement.classList.remove("dark");
		},
		setDarkMode() {
			this.mode = "dark";
			document.documentElement.classList.add("dark");
		},
		switchMode() {
			if (this.mode == "light") {
				this.setDarkMode();
			} else {
				this.setLightMode();
			}
		},
		async updateUser(payload, id) {
			const { user } = await userApi.update(payload, id);
            if(user && this.user._id === user._id) {
                this.user = { ...this.user, ...user };
                const curr = localStorage.getItem("UserStore");
                const toSet = JSON.stringify({ ...JSON.parse(curr), user: this.user });
                localStorage.setItem("UserStore", toSet);
            }
		},

		async updateUserCredentials(payload, id) {
			const { user } = await userApi.updateCredentials(payload, id);
			
		},
		async updateUserLoryCredentials(payload, id) {
			const { user } = await userApi.updateLoryCredentials(payload, id);
			if(user && this.user._id === user._id) {
                this.user = { ...this.user, ...user };
                const curr = localStorage.getItem("UserStore");
                const toSet = JSON.stringify({ ...JSON.parse(curr), user: this.user });
                localStorage.setItem("UserStore", toSet);
            }
		},
		deleteUserSession() {
			this.user = {};
			this.mode = null;
			this.usersList = [];
			localStorage.removeItem("UserStore");
		},
        async deleteUser(id) {
			const result = await userApi.delete(id);
			if (result) {
				await this.fetchUsers();
			}
		},
		async fetchUsers() {
			const { userList } = await userApi.readAll();
			this.usersList = userList;
		},
		async setLang(lang) {
			const curr = localStorage.getItem("UserStore");
			const { lang: currLang } = JSON.parse(curr);
        	this.lang = await loadTranslations(lang || this.lang || currLang || "en");
			const toSet = JSON.stringify({ ...JSON.parse(curr), lang: this.lang });
			localStorage.setItem("UserStore", toSet);
		},
	},
	persist: {
		storage: localStorage,
		paths: ["user", "mode", "sidebarCollapsed", "lang"], // Only these fields will be persisted
	},
});
