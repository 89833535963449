<template>
  <div class="flex gap-x-2">
    <span class="opacity-75 font-semibold">{{ label }}</span>
    <div class="flex gap-x-[2px] relative flex-[1]">
      <div
        v-for="({date, counters}, i) in data"
        :key="i"
        class="max-w-[6px] min-h-[22px] flex-[1] overflow-hidden rounded"
      >
        <div class="bg-gray-500 tooltip"
             :style="{ height: `${calculateBar(counters, 'pause')}%` }"
             :data-tooltip="`Pause ${calculateTooltip(date)} &#10;${calculateBar(counters, 'pause')}%`"
        />
        <div class="bg-red-500 tooltip"
             :style="{ height: `${calculateBar(counters, 'down')}%` }"
             :data-tooltip="`Down ${calculateTooltip(date)} &#10;${calculateBar(counters, 'down')}%`"
        />
        <div class="bg-green-500 tooltip"
             :style="{ height: `${calculateBar(counters, 'up')}%` }"
             :data-tooltip="`Up ${calculateTooltip(date)} &#10;${calculateBar(counters, 'up')}%`"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import dayjs from "dayjs";


const props = defineProps({
  label: String,
  data: { type: Array, default: () => [] },
  unit: { 
    type: String, 
    default: "d", 
  },
});
const { data, unit, label } = props;

function calculateDay(date) {
  // const currentDate = dayjs();
  // const sub = currentDate.subtract(index, "day");
  // const day = sub.format("DD-MMM");
  // return day;
  const d = dayjs(date);
  return d.format("DD-MMM");
}

function calculateHour(date) {
  // const currentDate = dayjs();
  // const sub = currentDate.subtract(index, "hour").minute(0).second(0);
  // const day = sub.format("HH:mm");
  // return day;
  const d = dayjs(date);
  return d.format("HH:mm");
}

function calculateTooltip(date) {
  switch (unit) {
    case "d":
      return calculateDay(date);
    case "h":
      return calculateHour(date);
    default:
      return calculateDay(date);
  }
}

function calculateBar(counters, type = "") {
  const { totalUP, totalDown, totalPaused, total } = counters;
  if(total == 0 && totalDown == 0 && totalUP == 0 && totalPaused == 0 ) return 0;
  let res = 0;
  switch (type) {
    case "up":
      res = (totalUP / total) * 100;
      break;
    case "down":
      res = (totalDown / total) * 100;
      break;
    case "pause":
      res = (totalPaused / total) * 100;
      break;
    default:
      res = 0;
      break;
  }
  return res.toFixed(2);
}

</script>

<style lang="postcss" scoped>
.tooltip {
  &::after {
    display: none;
    content: attr(data-tooltip);
    @apply absolute text-xs font-medium bg-zinc-600 text-white px-1.5 py-0.5 rounded-md;
    background-color: rgba(theme("colors.zinc.800"), 0.7);
    backdrop-filter: blur(3px);
    text-wrap: nowrap;
    bottom: calc(100% + 0.5rem);
    white-space: pre; 
  }

  &:hover {
    &::after {
      display: block;
    }
  }
}
</style>
