<template>
  <BaseBox v-for="pool in sortByDcName(pools)" :key="pool.id">
    <div class="flex justify-between mb-4">
      <h3 class="font-bold">{{ extractDcName(pool.dc_name) }} / {{ pool.name }}</h3>
      <h4 class="text-sm font-medium">Pool ID: {{ pool.id }}</h4>
    </div>
    <div class="grid grid-cols-4 gap-4">
      <div class="col-span-2 flex gap-2 items-start flex-wrap self-start">
        <template v-if="pool?.vmList?.length">
          <div
            v-for="vm in pool.vmList"
            :key="vm.id"
            class="vm-status"
            :data-tooltip="`${vm.name}`"
            :class="{ 'bg-green-500': vm.power_state === 'POWERED_ON' }"
          ></div>
        </template>
      </div>
      <div class="flex flex-col gap-2">
        <h4 class="font-bold uppercase">Info</h4>
        <div class="flex gap-2">
          <div class="flex-[1] flex flex-col gap-2">
            <p>{{ $t("label_virtual_machines") }}:</p>
          </div>
          <div class="flex-[1] flex flex-col gap-2">
            <p class="font-bold">{{ pool.vmCount }}</p>
          </div>
        </div>
        <hr class="opacity-50" />
        <div class="flex gap-2">
          <div class="flex-[1] flex flex-col gap-2">
            <p>vCPU:</p>
            <p>RAM:</p>
            <p>Storage:</p>
          </div>
          <div class="flex-[1] flex flex-col gap-2">
            <p class="font-bold">{{ pool.vcpu }}</p>
            <ElementWithUnit :value="pool.memory_mib" unit="MB" converter="data" ></ElementWithUnit>
            <ElementWithUnit :value="pool.storage_mib" unit="MB" converter="data" ></ElementWithUnit>
          </div>
        </div>
      </div>
      <div class="flex items-end justify-end">
        <BaseButton
          tag="RouterLink"
          :to="{ name: 'PoolSinglePage', params: { id: pool.id } }"
          >{{ $t("label_virtual_machines") }}
        </BaseButton>
      </div>
    </div>
  </BaseBox>
</template>

<script setup>
import ElementWithUnit from "@/components/ui/ElementWithUnit.vue";

defineProps({
  pools: {
    type: Array,
    default: () => [],
  }
});

function extractDcName(str){
  const match = str.match(/Datacenter-([^\s-]+)/);
  return match ? match[1] : null;
}

// Funzione per ordinare un array di oggetti
function sortByDcName(array) {
    return array.sort((a, b) => {
        // Ordina per dc_name (case insensitive)
        const dcNameComparison = a.dc_name.localeCompare(b.dc_name, undefined, { sensitivity: 'base' });
        
        if (dcNameComparison !== 0) {
            return dcNameComparison;
        }

        // Se dc_name è uguale, ordina per name (case insensitive)
        return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' });
    });
}

</script>

<style lang="postcss">
.vm-status {
  @apply bg-red-500 w-5 h-5 rounded opacity-90 hover:opacity-100 relative;

  &::after {
    display: none;
    content: attr(data-tooltip);
    @apply absolute font-medium bg-zinc-600 dark:bg-white/70 backdrop-blur-lg text-white dark:text-zinc-900 px-1.5 py-0.5 rounded-md font-bold;
    background-color: rgba(theme("colors.zinc.800"), 0.7);
    backdrop-filter: blur(3px);
    text-wrap: nowrap;
    bottom: calc(100% + 0.5rem);
    white-space: pre;
  }

  &:hover {
    &::after {
      display: block;
    }
  }
}
</style>
