<template>
    <p> {{ errorCode ? $t(message) + ' ' + $t('text_go_to') : $t(message) }} <RouterLink class="hover:underline hover:text-neptune-400 underline text-neptune-400"
            :to="{ name: 'DashboardPage' }">dashboard</RouterLink>
    </p>

</template>

<script setup>
const queryParams = new URLSearchParams(window.location.search);
const errorCode = queryParams.get("error_code");
const message = errorCode ? errorCode : "text_already_logged";  

</script>

<style lang="scss" scoped></style>