import * as yup from 'yup';
import { descriptionBaseSchema, hostnameOrIpBaseSchema, nameBaseSchema, serverPortSchema } from './common';
import {
  monitorAllowedStatesBaseSchema,
  monitorCheckToFailBaseSchema,
  monitorIntervalBaseSchema,
  monitorMaxTimeBaseSchema
} from './monitor';


const endpointWeightSchema = yup
    .number()
    .transform((v, o) => (o === "" ? null : v))
    .min(0)
    .max(100);

const endpointPortSchema = serverPortSchema
        .min(0)
        .nullable(true)
        .transform((value, originalValue) => {
          if (originalValue === '') return null;
          return value;
        })

const endpointSchema = yup.array(
    yup.object({
      target: hostnameOrIpBaseSchema,
      name: nameBaseSchema.required(),
      weight: endpointWeightSchema.required(),
    })
);


const monitoringSchema = yup.object({
    type: yup.string().required(),
    port: endpointPortSchema,
    needKeyword: yup.string()
                 .nullable(true),
    notNeedKeyword: yup.string()
                    .nullable(true),
    maxTime: monitorMaxTimeBaseSchema,
    interval: monitorIntervalBaseSchema,
    checkToFail: monitorCheckToFailBaseSchema,
    allowedStates: monitorAllowedStatesBaseSchema,
})

const poolSchema = yup.object({
  name: yup.string().required(),
  description: descriptionBaseSchema,
  type: yup.string().required(),
  endpoint: endpointSchema,
  monitoring: monitoringSchema
});


export {
    endpointSchema,
    monitoringSchema,
    endpointWeightSchema,
    endpointPortSchema,
}

export default poolSchema;