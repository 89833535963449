import { setLocale } from 'yup';
import { translate as t  } from "@/i18n";


import "./custom_methods";


export default setLocale ({
    mixed: {
      required: () => {
        return t("validation_error_required")
      },
      nullable: () => t("validation_error_required"),
    },
    string: {
      required: () => t("validation_error_required"),
      min: ({min, path}) => {
        let message = `${t("validation_error_min_string")} ${min}`
        if(path.toLowerCase().includes("password")) {
          message = `${t("validation_error_password_criteria")}`
        }
        return message
      },
      max: (val) => t("validation_error_max_string", {max: val.max}),
      email: () => t("validation_error_invalid_email"),
      length: ({length}) => `${t("validation_error_string_length", {length})} `,
      matches: (val) => {
        const {regex, path} = val;
        const { source } = regex;
        const isPassword = path.toLowerCase().includes("password");
        const map = {
          "[a-zA-Z]": !isPassword ? t("validation_at_least_one_letter") : t('validation_error_password_criteria') ,
          "\\d": !isPassword ? t("validation_at_least_one_number") : t('validation_error_password_criteria') ,
          "^[a-zA-Z0-9]*$": !isPassword ? t("validation_only_letters_and_numbers") : t('validation_error_password_criteria') ,
          "^(\\d+(,\\d+)*)?$": t('text_states_comma_separated'),
          "^[A-Z]+$": t('validation_error_uppercase'),
          '\\.$': t('validation_error_invalid_cname'),
          '^(?:[a-z0-9_.-]+)$': t('validation_error_record_srv_type'),
          '^\\+?[1-9]\\d{1,14}$': t('validation_error_invalid_phone_number'),
        }
        let message = t("validation_error_invalid_field");

        //console.log("🚀 ~ source:", source)
        
        const fromMap = map[source];

        if (fromMap) {
          message = fromMap;
        }

        return message;
      },
    },
    number: {
      min: (val) =>`${t("validation_error_min_value")} ${val.min}`,
      max: (val) => `${t("validation_error_max_value")} ${val.max}`,
      typeError: () => t("validation_number_type"),
    },
  });

