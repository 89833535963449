import * as yup from 'yup';
import { descriptionBaseSchema, serverPortSchema } from './common';

const dnsRecordValueSchema = yup
    .string()
    .required()
    .validateDnsRecordValue();


const dnsRecordKeySchema = yup
    .string()
    .max(60)
    .validateDnsRecordKey();

const recordPriorityBaseSchema = yup
    .number()
    .typeError()
    .min(0)
    .max(10);

const recordWeightBaseSchema = yup
    .number()
    .typeError()
    .min(0)
    .max(65535);

const recordSchema = yup.object({
  type: yup.string().required(),
  key: dnsRecordKeySchema.requiredBySrv().requiredByPtr(),
  ttl: yup.string().requiredBySrv(),
  pool: yup.string(),
  description: descriptionBaseSchema,
  priority: recordPriorityBaseSchema.requiredBySrv(),
  weight: recordWeightBaseSchema.requiredBySrv(),
  value: dnsRecordValueSchema,
  port: serverPortSchema.requiredBySrv()
});

export {
    dnsRecordKeySchema,
    dnsRecordValueSchema,
    recordPriorityBaseSchema,
    recordWeightBaseSchema
}

export default recordSchema