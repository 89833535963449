<template>
	<form class="space-y-4" @submit="onSubmit">
		<!-- Name and Surname -->
		<div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
			<BaseInput id="name" :label="$t('label_name')" name="name" :placeholder="$t('placeholder_name')" type="text" required />
			<BaseInput id="surname" :label="$t('label_surname')" name="surname" :placeholder="$t('placeholder_surname')" type="text" required />
		</div>

		<!-- Email and Phone -->
		<div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
			<BaseInput id="email" :label="$t('label_email')" name="email" :placeholder="$t('placeholder_email')" type="email" required />
			<BaseInput id="phone" :label="$t('label_phone')" name="phone" :placeholder="$t('placeholder_phone')" type="text" required />
		</div>

		<div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
			<!-- Organization -->
			<BaseSelect
				v-if="checkPermission('*')"
				:label="$t('label_organization')"
				class="min-w-32"
				name="organizationID"
				:options="[
					{ value: '', label: '' },
					...organizationOptions,
				]"
				:initialValue="initialValues.organizationID ?? null"
				required />
			<BaseInput v-else 
ì					   :label="$t('label_organization')" 
					   name="organizationID" 
					   :placeholder="$t('placeholder_organization')" 
					   type="text" 
					   disabled 

			/>
			<!-- Role -->
			<BaseSelect
				v-if="checkPermission('can_assign_role')"
				:label="$t('label_role')"
				class="min-w-32"
				name="role"
				:initialValue="initialValues.role ?? null"
				:options="[
					{ value: '', label: '' },
					...rolesOptions,
				]"
				required />
		</div>

		<!-- Save Button -->
		<div class="text-right mt-4">
			<BaseButton size="sm" :disabled="isSubmitting || !meta.valid">{{ isEditing ? $t("label_update") : $t("label_add") }}</BaseButton>
		</div>
	</form>
</template>

<script setup>
	import { ref, computed } from "vue";
	import { useForm } from "vee-validate";
	import useToastStore from "@/stores/toast";
	import { useAuthStore } from "@/stores/AuthStore";
	import { useUserStore } from "@/stores/UserStore";
	import { useOrganizationsStore } from "@/stores/OrganizationsStore";
	import { useIdpStore } from "@/stores/IdpStore";
	import { i18n } from "@/i18n";
	import { capitalizeFirst } from "@/utilities/helpers";
	import userSchema from "@/validations/schemes/user";

	const { t } = i18n.global;
	const authStore = useAuthStore();
	const userStore = useUserStore();
	const organizationsStore = useOrganizationsStore();
	const idpStore = useIdpStore();
	const currentUser = useUserStore().user;

	const { checkPermission } = authStore;
	const { addToast } = useToastStore();

	const { initialValues, isEditing}   = defineProps({
		initialValues: {
			type: Object,
			default: () => ({}),
		},
		isEditing:{
			type: Boolean,
			default: false
		}
	});

	const error = ref("");
	const emit = defineEmits(["closeModal"]);

	const organizationOptions = computed(() =>
		organizationsStore.organizations.map((organization) => ({
			value: organization.id,
			label: organization.zoho_customer_id + " / " + organization.name
		}))
	);

	const rolesOptions = computed(() =>
		idpStore.roleList.map((role) => ({
			value: role.slug,
			label: role.name
		}))
	);

	const schema = userSchema;
	const { isSubmitting, handleSubmit, meta } = useForm({
		validationSchema: schema,
		initialValues:  {
			_id: initialValues._id || '',
			name: initialValues.name || '',
			surname: initialValues.surname || '',
			email: initialValues.email || '',
			phone: initialValues.phone || '',
			organizationID: userStore.isAdmin ? (initialValues.organizationID || currentUser.organizationID) : organizationsStore.currentOrganization.name,
			role: initialValues.role || 'manager',
		},
	});

	const onSubmit = handleSubmit.withControlled(async (values) => {
		try {
			if(!checkPermission('*')/* checkPermission('can_assign_organization') */) values.organizationID = currentUser.organizationID;
			if(!checkPermission('*')/* checkPermission('can_assign_role') */) values.role = 'manager';
			const castValues = schema.cast(values);
			if(isEditing){
				const id = initialValues._id
				await userStore.updateUser(castValues, id);
			} else await authStore.inviteUser(castValues);

			await userStore.fetchUsers();

			emit("closeModal");
			const updateTitle = t("text_update_generic_success");
			const inviteTitle = `${t("label_user")} ${t("text_invite_generic_success")}`;
			const title = isEditing ? updateTitle : inviteTitle;
			addToast({
				title: capitalizeFirst(title),
				variant: "success",
			});
		} catch (err) {
			addToast({
				title: `${t("text_invite_generic_failure")}`,
				content: error.value || err,
				variant: "danger",
			});
		}
	});
</script>
