import BaseService from "../../BaseService";

export default class Auth extends BaseService {
  async login(payload) {
    const { data } = await this._apiClient.post(
      `/${this._resource}/login`,
      payload
    );
    return data;
  }
  async logout() {
    const { data } = await this._apiClient.post(`/${this._resource}/logout`);
    return data;
  }
  async requestReset(payload) {
    const { data } = await this._apiClient.post(
      `/${this._resource}/request-reset`,
      payload
    );
    return data;
  }
  async requestVerifyToken(payload) {
    const { data } = await this._apiClient.post(
      `/${this._resource}/verify-reset-token`,
      payload
    );
    return data;
  }
  async resetCredentials(payload) {
    console.log(payload);
    const { data } = await this._apiClient.post(
      `/${this._resource}/confirm-reset`,
      payload
    );
    return data;
  }
  async inviteUser(payload) {
    console.log(payload);
    const { data } = await this._apiClient.post(
      `/${this._resource}/invitation/send`,
      payload
    );
    return data;
  }
  async acceptInvitation(payload) {
    console.log(payload);
    const { data } = await this._apiClient.post(
      `/${this._resource}/invitation/accept`,
      payload
    );
    return data;
  }
}
